import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import BarChart from '../BarChart';
import CardView from '../CardView';
import FeedListing from '../FeedListing';

const useStyles = makeStyles({
  contentWrapper: {
    padding: '70px 0',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});

function FeedAndChart({ feed, chart, background }) {
  const classes = useStyles();

  if (!feed.title && !chart.title) return null;

  const feedContent = feed.title && (
    <FeedListing
      type={feed.feedType}
      archiveLinkText={feed.archiveLinkText}
      itemCount={feed.itemCount}
      title={feed.title}
    />
  );
  const chartContent = chart.title && (
    <BarChart
      title={chart.title}
      values={[chart.permissions, chart.samples]}
      labels={[chart.permissions_label, chart.samples_label]}
    />
  );
  return (
    <div className={classes.contentWrapper} style={background ? { backgroundImage: `url(${background.data.attributes.url})` } : {}}>
      <Container maxWidth="md">
        <CardView
          leftColumn={feedContent}
          rightColumn={chartContent}
          ratio={{ left: 7, right: 5 }}
        />
      </Container>
    </div>
  );
}

export default FeedAndChart;
