import React from 'react';
import {
  Container,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: '20px 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
}));

function FullWidthExtra({ content, titleId = null }) {
  const classes = useStyles();
  return (
    <Container maxWidth="md" component="section" className={classes.contentWrapper} aria-labelledby={titleId}>
      { content }
    </Container>
  );
}

export default FullWidthExtra;
