import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { uploadUrl } from '../utils/url';
import RichText from './RichText';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '16rem',
    margin: '0 16px 16px 0',
  },
  image: {
    maxWidth: '16rem',
    paddingBottom: '16px',
  },
  title: {
    margin: 0,
  },
  hidden: {
    display: 'none',
  },
  infoCard: {
    '& p': {
      margin: 0,
    },
    '& h3': {
      paddingBottom: '13px',
    },
  },
}));

function InfoCard({
  direction,
  heading,
  items,
}) {
  const classes = useStyles();

  if (!heading && items.length === 0) return null;
  return (
    <Grid container className={classes.infoCard}>
      <Grid item xs={12} className={!heading ? classes.hidden : null}>
        <h2>{heading}</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction={direction}
        >
          {
            items.map((item) => (
              <Grid
                key={item.id}
                item
                className={
                  !item.title
                  && !item.image.data.attributes
                  && !item.body
                    ? classes.hidden : classes.card
                }
              >
                {
                  item.image.data
                    ? (
                      <img
                        src={uploadUrl(item.image.data.attributes.url)}
                        alt={item.altText || ''}
                        className={classes.image}
                      />
                    ) : null
                }
                <h3 className={!item.title ? classes.hidden : classes.title}>{item.title}</h3>
                <RichText html={item.body ? item.body : null} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoCard;
