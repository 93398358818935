import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  navList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      margin: 0,
      padding: 0,
      '& a': {
        padding: '12px 0',
        display: 'block',
        fontSize: '1.0625rem',
        fontWeight: 700,
      },
    },
  },
});

function SubNavItems({ pageList, activeSlug }) {
  if (!pageList || pageList.length === 0) return null;

  const classes = useStyles();
  return (
    <ul className={classes.navList}>
      {
        pageList.map((item) => (
          <li key={item.slug}>
            {
              item.slug === activeSlug
                ? <Link to={item.slug} className="active" aria-current="page">{item.title}</Link>
                : <Link to={item.slug}>{item.title}</Link>
            }
          </li>
        ))
      }
    </ul>
  );
}

export default SubNavItems;
