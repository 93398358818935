import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LiveMessage } from 'react-aria-live';
import { makeStyles } from '@material-ui/core';
import { queryFeedItemsBySlug } from '../utils/queries';
import RichText from './RichText';
import { formatDateTime } from '../utils/format';
import { i18n } from '../utils/const';
import LoadedContent from './LoadedContent';

const useStyles = makeStyles((theme) => ({
  itemTitle: {
    fontSize: '1.75rem',
    marginTop: '1.25rem',
    lineHeight: '2.25rem',
    marginBottom: '1.25rem',
    color: theme.palette.heading,
  },
}));

function FeedPageItem({
  type, slug, setDocumentTitle, isFeedRoot,
}) {
  const classes = useStyles();
  const { lang } = useParams();
  const [content, setContent] = useState(null);
  const { loading, error, data } = useQuery(queryFeedItemsBySlug(type), {
    variables: { slug },
    skip: !slug,
  });

  const noContent = <p>{isFeedRoot ? i18n[lang].noArticles : i18n[lang].articleNotFSound}</p>;

  useEffect(() => {
    if (data) {
      if (data.items.length === 0) {
        setContent(noContent);
      } else {
        const item = data.items.data[0].attributes;
        setContent(
          <article>
            <LiveMessage aria-live="assertive" message={item.title} />
            <p>{formatDateTime(item.published)}</p>
            {isFeedRoot ? (
              <h2 id="feed-item-title">{item.title}</h2>
            ) : (
              <h1 className={classes.itemTitle} id="main-title">
                {item.title}
              </h1>
            )}
            <RichText html={item.body} />
          </article>,
        );
        if (!isFeedRoot) {
          setDocumentTitle(item.title);
        }
      }
    }
  }, [data]);

  if (!slug) return noContent;
  if (error) return error.message;

  return <LoadedContent isLoading={loading}>{content}</LoadedContent>;
}

export default FeedPageItem;
