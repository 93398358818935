import React from 'react';
import Boxed from './Boxed';

function OneCol({ content }) {
  return (
    <Boxed>
      { content }
    </Boxed>
  );
}

export default OneCol;
