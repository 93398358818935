import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Boxed from './Boxed';

const useStyles = makeStyles((theme) => ({
  contentCol: {
    paddingLeft: (theme.asideRight ? 0 : 20),
    paddingRight: (theme.asideRight ? 20 : 0),
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
  asideCol: {
    wordBreak: 'break-word',
  },
}));

function ContentAndAside({
  aside,
  content,
  asideRight = false,
  asideProps = {},
  contentProps = {},
}) {
  const classes = useStyles(asideRight);

  const ContentColumn = () => (
    <Grid
      item
      md={9}
      xs={12}
      className={classes.contentCol}
      {...contentProps}
    >
      { content }
    </Grid>
  );

  const AsideColumn = () => (
    <Grid item md={3} xs={12} component="aside" className={classes.asideCol} {...asideProps}>
      { aside }
    </Grid>
  );

  return asideRight
    ? (
      <Boxed>
        <ContentColumn />
        <AsideColumn />
      </Boxed>
    )
    : (
      <Boxed>
        <AsideColumn />
        <ContentColumn />
      </Boxed>
    );
}

export default ContentAndAside;
