import { gql } from '@apollo/client';
import { TYPE_BLOGS, TYPE_NEWS } from './const';

const feedTypes = {
  [TYPE_NEWS]: 'newsItems',
  [TYPE_BLOGS]: 'blogs',
};

const assertValidLanguageCode = (lang) => {
  if (!(lang === 'fi' || lang === 'sv' || lang === 'en')) {
    throw new Error(`Unknown language code ${lang}`);
  }
};

const querySiteData = () => (
  gql`
  query {
    configuration {
      data {
        id
        attributes {
          sections {
            label_fi
            label_en
            label_sv
            banner {
              data {
                attributes {
                  url
                  mime
                }
              }
            }
            page: rootPage {
              data {
                attributes {
                  slug_fi
                  slug_en
                  slug_sv
                  title_fi
                  title_en
                  title_sv
                }
              }
            }
          }
          sections {
            subPages {
              page {
                data {
                  attributes {
                    slug_fi
                    slug_en
                    slug_sv
                    title_fi
                    title_en
                    title_sv
                  }
                }
              }
            }
          }
        }
        attributes {
          mainPage {
            data {
              id
              attributes {
                slug_fi
                slug_en
                slug_sv
              }
            }
          }
          blogsPage {
            data {
              id
              attributes {
                slug_fi
                slug_en
                slug_sv
              }
            }
          }
          newsPage {
            data {
              id
              attributes {
                slug_fi
                slug_en
                slug_sv
              }
            }
          }
          siteName_fi
          siteName_en
          siteName_sv
          footerContact_fi
          footerContact_en
          footerContact_sv
          twitterUrl
          facebookUrl
          instagramUrl
          defaultBanner {
            data {
              attributes {
                url
                mime
              }
            }
          }
        }
      }
    }
  }
  `
);

const queryMainPageContent = (lang) => {
  assertValidLanguageCode(lang);
  return gql`
  query($page_id: ID!) {
    page(id: $page_id) {
      data {
        id
        attributes {
          title: title_${lang}
          slug_fi
          slug_en
          slug_sv
          banner {
            data {
              attributes {
                alternativeText
                name
                url
                mime
              }
            }
          }
          content {
            ... on ComponentContentBody {
              id
              content: content_${lang}
            }
          }
        }
      }
    }
  }
  `;
};

const queryPagesWithSlug = (lang) => {
  assertValidLanguageCode(lang);
  return gql`
    query($slug: String!) {
      pages (filters: {slug_${lang}:{eq: $slug}}) {
        data {
          id
          attributes {
            title: title_${lang}
            slug_fi
            slug_sv
            slug_en
            banner {
              data {
                attributes {
                  alternativeText
                  url
                  name
                  mime
                }
              }
            }
            layout
            content {
              ... on ComponentContentBody {
                id
                content: content_${lang}
              }
              ... on ComponentContentImageGrid {
                id
                imagesPerRow
                imageLink {
                  id
                  link_url
                  image {
                    data {
                      id
                      attributes {
                        mime
                        url
                        name
                      }
                    }
                  }
                  altText: image_alt_${lang}
                }
              }
              ... on ComponentContentInfoCard {
                id
                direction
                heading: heading_${lang}
                Items {
                  id
                  title: title_${lang}
                  body: body_${lang}
                  image {
                    data {
                      attributes {
                        url
                        name
                        mime
                      }
                    }
                  }
                  altText: alt_text_${lang}
                }
              }
              ... on ComponentContentAccordion {
                id
                AccordionItem {
                  id
                  title: title_${lang}
                  body: body_${lang}
                }
              }
              ... on ComponentFullWidthLayoutTwoFeedsInSeparateColumns {
                id
                LeftFeed {
                  id
                  feedType
                  itemCount
                  title: title_${lang}
                  archiveLinkText: archiveLinkText_${lang}
                }
                RightFeed {
                  id
                  feedType
                  itemCount
                  title: title_${lang}
                  archiveLinkText: archiveLinkText_${lang}
                }
              }
              ... on ComponentFullWidthLayoutFeedAndChart {
                id
                feed {
                  id
                  feedType
                  itemCount
                  title: title_${lang}
                  archiveLinkText: archiveLinkText_${lang}
                }
                chart {
                  id
                  permissions
                  samples
                  title: title_${lang}
                  permissions_label: permissions_label_${lang}
                  samples_label: samples_label_${lang}
                }
                background {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            extra {
              ... on ComponentSocialMediaJuicerFeed {
                id
                feedId: feedID_${lang}
                title: title_${lang}
                showBranding
              }
            }
          }
        }
      }
    }`;
};

const queryFeedList = (lang, type) => {
  assertValidLanguageCode(lang);
  if (!feedTypes[type]) throw new Error(`Unknown feed type "${type}`);
  return gql`
    query($count: Int!) {
      items: ${feedTypes[type]} (pagination:{limit:$count}, sort: "publicationTime:desc", filters:{language: {eq: "${lang}"} } ) {
        data {
          id
          attributes {
            language
            title
            summary
            published: publicationTime
            slug
          }
        }
      }
      configuration {
        data {
          id
          attributes {
            feedPage: ${type}Page {
              data {
                id
                attributes {
                  slug: slug_${lang}
                }
              }
            }
          }
        }
      }
    }
  `;
};

const queryFeedPageData = (lang, type) => {
  assertValidLanguageCode(lang);
  if (!feedTypes[type]) throw new Error(`Unknown feed type "${type}`);
  return gql`
    query {
      items: ${feedTypes[type]} ( pagination:{limit:50} sort: "publicationTime:desc", filters: {  language: {eq: "${lang}" }}) {
        data {
          id
          attributes {
            title
            published: publicationTime
            slug
          }
        }
      }
    }`;
};

const queryFeedItemsBySlug = (type) => {
  if (!feedTypes[type]) throw new Error(`Unknown feed type "${type}`);
  return gql`
  query($slug: String!) {
    items: ${feedTypes[type]} (filters: { slug: {eq:$slug} }) {
      data {
        id
        attributes {
          language
          title
          body: fullText
          published: publicationTime
          slug
        }
      }
    }
  }
  `;
};

export {
  querySiteData,
  queryPagesWithSlug,
  queryMainPageContent,
  queryFeedList,
  queryFeedPageData,
  queryFeedItemsBySlug,
};
