import React from 'react';
import ContentContainer from './ContentContainer';

function FullWidth({ content }) {
  return content.map((item) => (
    item.props.fullWidthLayout // don't box components that manage their own wrapper
      ? item
      : (
        <ContentContainer key={item.key}>
          { item }
        </ContentContainer>
      )
  ));
}

export default FullWidth;
