import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    position: 'relative',
    zIndex: 1,
  },
  titleWrapper: {
    minHeight: 170,
    paddingTop: 0,
  },
  titleWrapperMain: {
    minHeight: 370,
    paddingTop: 50,
  },
  mainTitle: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: '2.75rem',
    textAlign: 'left',
    paddingBottom: 0,
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
    textShadow: '0px 1px 2px #004B87, 0px -1px 2px #004B87, -1px 0px 2px #004B87, 1px 0px 2px #004B87',
    lineHeight: 1.125,
    color: '#fff',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  mainTitleMain: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: '3.75rem',
    textAlign: 'center',
    paddingBottom: 30,
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
    textShadow: '0px 1px 2px #004B87, 0px -1px 2px #004B87, -1px 0px 2px #004B87, 1px 0px 2px #004B87',
    lineHeight: 1.125,
    color: '#fff',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: '3.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75rem',
    },
  },
}));

function MainContent({
  title,
  content,
  mainPage,
  isNotMainTitle = false,
}) {
  const classes = useStyles(mainPage);
  return (
    <div>
      <main id="main-content" aria-labelledby="main-title" className={classes.mainContent}>
        {mainPage
          ? (
            <Grid container direction="column" justifyContent="center" className={classes.titleWrapperMain}>
              <h1 className={classes.mainTitleMain} id="main-title">{title}</h1>
            </Grid>
          )
          : (
            <Grid container direction="column" justifyContent="center" className={classes.titleWrapper}>
              <h2 className={classes.mainTitle}>{title}</h2>
            </Grid>
          )}
        { content }
      </main>

    </div>
  );
}

export default MainContent;
