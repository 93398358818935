import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './components/Spinner';
import { querySiteData } from './utils/queries';
import Page from './view/Page';
import { LANGUAGE_CODES, TYPE_BLOGS, TYPE_NEWS } from './utils/const';
import { parseSiteStructure } from './utils/cmsContentParser';

const App = () => {
  const { loading, error, data } = useQuery(querySiteData());
  const [siteStructure, setSiteStructure] = useState(null);
  const [siteConfig, setSiteConfig] = useState(null);
  // if lang not in path
  let defaultLang = localStorage.getItem('lang');
  // enforce language codes
  if (!LANGUAGE_CODES.reduce((a, b) => a || b === defaultLang, false)) {
    localStorage.removeItem('lang');
    defaultLang = 'fi';
  }
  useEffect(() => {
    if (data) {
      const feedSlugs = { fi: {}, sv: {}, en: {} };
      const conf = data.configuration.data.attributes;
      const configuration = conf;
      if (configuration.sections) {
        const { sections, blogsPage, newsPage } = configuration;
        const parsedStructure = {
          fi: parseSiteStructure(sections, 'fi'),
          sv: parseSiteStructure(sections, 'sv'),
          en: parseSiteStructure(sections, 'en'),
        };
        if (blogsPage) {
          if (blogsPage.data.attributes.slug_fi) {
            feedSlugs.fi[blogsPage.data.attributes.slug_fi] = TYPE_BLOGS;
          }
          if (blogsPage.data.attributes.slug_fi) {
            feedSlugs.sv[blogsPage.data.attributes.slug_sv] = TYPE_BLOGS;
          }
          if (blogsPage.data.attributes.slug_fi) {
            feedSlugs.en[blogsPage.data.attributes.slug_en] = TYPE_BLOGS;
          }
        }
        if (newsPage) {
          if (newsPage.data.attributes.slug_fi) {
            feedSlugs.fi[newsPage.data.attributes.slug_fi] = TYPE_NEWS;
          }
          if (newsPage.data.attributes.slug_sv) {
            feedSlugs.sv[newsPage.data.attributes.slug_sv] = TYPE_NEWS;
          }
          if (newsPage.data.attributes.slug_en) {
            feedSlugs.en[newsPage.data.attributes.slug_en] = TYPE_NEWS;
          }
        }
        setSiteStructure(parsedStructure);
      }
      const commonConfig = {
        twitterUrl: configuration.twitterUrl,
        facebookUrl: configuration.facebookUrl,
        instagramUrl: configuration.instagramUrl,
        defaultBanner: configuration.defaultBanner.data.attributes,
      };
      setSiteConfig({
        fi: {
          ...commonConfig,
          siteName: configuration.siteName_fi,
          footerContact: configuration.footerContact_fi,
          mainPageSlug: configuration.mainPage.data.attributes.slug_fi,
          feedSlugs: feedSlugs.fi,
        },
        sv: {
          ...commonConfig,
          siteName: configuration.siteName_sv,
          footerContact: configuration.footerContact_sv,
          mainPageSlug: configuration.mainPage.data.attributes.slug_sv,
          feedSlugs: feedSlugs.sv,
        },
        en: {
          ...commonConfig,
          siteName: configuration.siteName_en,
          footerContact: configuration.footerContact_en,
          mainPageSlug: configuration.mainPage.data.attributes.slug_en,
          feedSlugs: feedSlugs.en,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const element = document.getElementById('startSpinner');
    if (element) {
      element.remove();
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('aria-busy', loading);
  }, [loading]);

  if (loading) return <Spinner lang={defaultLang} fullWindow />;
  if (error) return error.message;
  return (
    siteStructure && (
      <div className="App">
        <Switch>
          <Route path="/fi">
            <LanguageBase lang="fi" siteStructure={siteStructure.fi} siteConfig={siteConfig.fi} />
          </Route>
          <Route path="/sv">
            <LanguageBase lang="sv" siteStructure={siteStructure.sv} siteConfig={siteConfig.sv} />
          </Route>
          <Route path="/en">
            <LanguageBase lang="en" siteStructure={siteStructure.en} siteConfig={siteConfig.en} />
          </Route>
          <Route exact path="/">
            <Redirect to={`/${defaultLang}`} />
          </Route>
          <Route path="*">
            <Redirect to={`/${defaultLang}/not-found`} />
          </Route>
        </Switch>
      </div>
    )
  );
};

function LanguageBase({ lang, siteStructure, siteConfig }) {
  if (lang) localStorage.setItem('lang', lang);
  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);
  return siteStructure ? (
    <>
      <Switch>
        <Route path="/:lang/:slug">
          <Page
            siteStructure={siteStructure}
            siteConfig={siteConfig}
            mainPageSlug={siteConfig.mainPageSlug}
            feedSlugs={siteConfig.feedSlugs}
          />
        </Route>
        <Route exact path="/:lang">
          <Redirect to={`/${lang}/${siteConfig.mainPageSlug}`} />
        </Route>
      </Switch>
    </>
  ) : null;
}

export default App;
