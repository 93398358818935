import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, Link,
} from '@material-ui/core';
import { logoAlt, i18n } from '../utils/const';
import { externalUrl } from '../utils/url';
import RichText from '../components/RichText';
import ModalComponent from '../components/Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    margin: 0,
    '& h2, & h3': {
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: '0.75rem',
    },
  },
  container: {
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
    color: 'white',
    padding: '50px 10px',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignContent: 'center',
      padding: '40px 20px',
    },
  },
  leftArea: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignContent: 'center',
    },
  },
  leftItem: {
    height: '50%',
    width: '100%',
  },
  logoArea: {
    width: '100%',
  },
  logo: {
    display: 'inline-block',
    width: '260px',
    margin: '0 auto 32px',
  },
  rightArea: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: '32px',
    },
    '& p': {
      marginTop: 0,
      fontSize: '0.9375rem',
    },
  },
  socialMedia: {
    marginRight: 10,
    width: 30,
  },
  siteMapBtn: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    margin: 0,
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

function Footer({
  contactHTML,
  twitterUrl,
  facebookUrl,
  instagramUrl,
  lang,
  sections,
  activeSlug,
  mainPageSlug,
  siteName,
}) {
  const classes = useStyles();
  const [modalVisible, setModalVisibility] = useState(false);

  const SoMeLink = ({ to, label, imgUrl }) => {
    if (!to) return null;
    return (
      <Link href={externalUrl(to)}>
        <img alt={label} src={imgUrl} className={classes.socialMedia} />
      </Link>
    );
  };

  return (
    <Box className={classes.root} component="footer">
      <Grid
        container
        className={classes.container}
        justifyContent="space-between"
      >
        <div className={classes.rightArea}>
          <RichText html={contactHTML} />
          <button
            type="button"
            disabled={modalVisible}
            onClick={() => setModalVisibility(true)}
            className={classes.siteMapBtn}
          >
            {i18n[lang].siteMap.toUpperCase()}
          </button>
          <ModalComponent
            visible={modalVisible}
            handleVisibility={setModalVisibility}
            sections={sections}
            lang={lang}
            activeSlug={activeSlug}
            mainPageSlug={mainPageSlug}
            siteName={siteName}
          />
        </div>
        <Grid item>
          <Grid
            container
            className={classes.leftArea}
            direction="column"
            alignContent="flex-start"
          >
            <img className={classes.logo} src="/assets/logo_w260.png" srcSet="/assets/logo_w520.png 2x" alt={logoAlt[lang]} />
            <div>
              <SoMeLink to={facebookUrl} label="facebook" imgUrl="/assets/fb.png" />
              <SoMeLink to={twitterUrl} label="twitter" imgUrl="/assets/twitter.png" />
              <SoMeLink to={instagramUrl} label="instagram" imgUrl="/assets/ig.png" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
