function uploadUrl(file) {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL + file;
  }
  return file;
}

function externalUrl(url) {
  if (!url) return '';
  // if no protocol, force https
  if (!/^https?:\/\/.*/.test(url)) {
    return `https://${url}`;
  }
  return url;
}

export { uploadUrl, externalUrl };
