import DOMPurify from 'dompurify';

const config = {
  ADD_TAGS: ['iframe'],
};

function sanitizeHtmlString(source) {
  const clean = DOMPurify.sanitize(source, config);
  const cleanObj = { __html: clean };
  return cleanObj;
}

export default sanitizeHtmlString;
