import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { queryFeedList } from '../utils/queries';
import { formatDateTime } from '../utils/format';
import { i18n } from '../utils/const';
import { asideTitle } from '../utils/styles';
import LoadedContent from './LoadedContent';

const useStyles = makeStyles((theme) => ({
  asideTitle,
  archiveLink: {
    textTransform: 'uppercase',
    fontWeight: 800,
    '&:before': {
      content: '"»"',
      display: 'inline-block',
    },
  },
  article: {
    '& h3': {
      margin: '.2em 0 0',
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    '& p': {
      margin: '0 0 1em',
    },
    '& a': {
      color: theme.palette.heading,
      '&:hover': {
        color: '#000',
      },
    },
    '& .date': {
      margin: '1em 0 0',
      fontSize: '0.875rem',
      fontWeight: 800,
      color: theme.palette.secondary.main,
    },
  },
}));

function FeedListing({
  type,
  title,
  titleId = null,
  archiveLinkText,
  itemCount = 50,
}) {
  const classes = useStyles();
  const { lang } = useParams();
  const { loading, error, data } = useQuery(queryFeedList(lang, type), {
    variables: { count: itemCount },
  });

  if (error) return 'error';

  const ListContent = () => {
    if (!data) return null;

    if (data.items.length === 0) {
      return <p>{i18n[lang].noArticles}</p>;
    }

    return (
      <>
        {
          data.items.data.map((item, i) => (
            <article key={item.id} className={classes.article} aria-labelledby={`feed-item-title-${i}`}>
              <p className="date">{formatDateTime(item.attributes.published)}</p>
              <h3 id={`feed-item-title-${i}`}>
                <Link to={`/${lang}/${data.configuration.data.attributes.feedPage.data.attributes.slug}/${item.attributes.slug}`}>{item.attributes.title}</Link>
              </h3>
              <p>{item.attributes.summary}</p>
            </article>
          ))
        }
        <Link to={`/${lang}/${data.configuration.data.attributes.feedPage.data.attributes.slug}`} className={classes.archiveLink}>
          {archiveLinkText}
        </Link>
      </>
    );
  };

  return (
    <>
      <h2 className={classes.asideTitle} id={titleId}>{title}</h2>
      <LoadedContent isLoading={loading}>
        <ListContent />
      </LoadedContent>
    </>
  );
}

export default FeedListing;
