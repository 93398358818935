const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  // const HH = date.getHours();
  // const MM = date.getMinutes().toString().padStart(2, '0');
  return `${dd}.${mm}.${yyyy}`;
};

// eslint-disable-next-line import/prefer-default-export
export { formatDateTime };
