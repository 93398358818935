function verticalLine(color) {
  const canvas = document.createElement('canvas');
  canvas.width = 15;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 13, 1);
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(13, 0, 15, 1);
  return ctx.createPattern(canvas, 'repeat');
}

function horizontalLine(color) {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 15;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 13);
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 13, 1, 15);
  return ctx.createPattern(canvas, 'repeat');
}

export default { verticalLine, horizontalLine };
