import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Grid,
  Hidden,
  IconButton,
} from '@material-ui/core';
import { MenuRounded, CloseRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { i18n } from '../utils/const';
import Banner from '../components/Banner';
import LanguagePicker from '../components/LanguagePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    color: 'white',
    position: 'relative',
    paddingTop: 50, // gutter for language picker
  },
  topArea: {
    position: 'relative',
    zIndex: 3,
    padding: '0 50px',
    minHeight: 160,
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  logo: {
    width: '100%',
    maxWidth: '300px',
  },
  linkArea: {
    marginTop: '13px',
  },
  navMenu: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  navMenuItem: {
    display: 'inline-block',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      display: 'block',
      padding: '5px 3px',
      textTransform: 'uppercase',
      fontWeight: 700,
      transition: 'background-color 200ms ease, border 200ms ease, color 200ms ease',
    },
    '&.active a': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  desktopNavMenuItem: {
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    marginLeft: '1em',
    '& a, & .linkWrap': {
      borderBottom: '1px solid transparent',
      textShadow: '0px 1px 2px #004B87, 0px -1px 2px #004B87, -1px 0px 2px #004B87, 1px 0px 2px #004B87',
    },
    '& .linkWrap': {
      borderBottom: '1px solid transparent',
    },
    '&:hover': {
      textShadow: 'none',
    },
    '&.active': {
      borderBottomColor: theme.palette.secondary.light,
      '& a': {
        borderBottomColor: theme.palette.secondary.light,
      },
      '& .linkWrap': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  mobileMenuPaper: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  mobileNavMenuItem: {
    display: 'block',
    '& a': {
      padding: '15px 20px',
      borderTop: '1px solid #333',
      borderLeft: '4px solid transparent',
      margin: 0,
    },
    '&.active a': {
      borderLeftColor: theme.palette.secondary.light,
      borderBottom: 'none',
    },
    '&.active:hover a': {
      borderLeftColor: '#fff',
    },
  },
  iconButton: {
    outline: 'none',
    padding: '.2em',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    '&:focus, &:hover': {
      outline: '5px auto -webkit-focus-ring-color',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  mobileMenuOpen: {
    fontSize: '2.5rem',
    color: '#fff',
  },
  mobileMenuClose: {
    padding: '10px 20px',
    color: '#fff',
    fontSize: '1.125rem',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: 'none',
    lineHeight: 1,
    textTransform: 'uppercase',
    outline: 'revert',
  },

}));

function Header({
  siteStructure,
  banner,
  mainPage = false,
  slug,
  lang,
  languageVersionSlugs,
}) {
  const classes = useStyles();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuFirstItemRef = useRef();
  const mobileMenuLastItemRef = useRef();
  const mobileMenuStartRef = useRef();
  const { index, sections } = siteStructure;
  const currentPage = index[slug];
  // page can either be section root page or sub page or might not be in index at all
  const currectSection = currentPage ? currentPage.parent || currentPage : {};
  const currentSectionSlug = currectSection.slug || '';
  useEffect(() => {
    if (mobileMenuOpen) {
      mobileMenuFirstItemRef.current.focus();
    }
  });
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMoveToMenuTop = (event) => {
    if (mobileMenuOpen && event.keyCode === 9 && !event.shiftKey) {
      mobileMenuStartRef.current.focus();
    }
  };

  const handleMoveToMenuBottom = (event) => {
    if (mobileMenuOpen && event.keyCode === 9 && event.shiftKey) {
      mobileMenuLastItemRef.current.focus();
    }
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const NavList = ({ isMobileMenu = false }) => {
    const NavItems = ({ items }) => items.map((item, i) => {
      const isCurrentSection = item.slug === currentSectionSlug;
      const isCurrentPage = item.slug === slug;
      const viewTypeClassName = isMobileMenu
        ? classes.mobileNavMenuItem
        : classes.desktopNavMenuItem;
      const activeClassName = isCurrentSection ? 'active' : '';
      const classNames = `${classes.navMenuItem} ${viewTypeClassName} ${activeClassName}`;
      let ariaCurrent = null;
      if (isCurrentSection) ariaCurrent = 'true';
      if (isCurrentPage) ariaCurrent = 'page';
      return (
        <li key={item.title} role="none" className={classNames}>
          <div className="linkWrap">
            <Link
              to={`/${lang}/${item.slug}`}
              aria-current={ariaCurrent}
              onClick={closeMobileMenu}
              onKeyDown={i === items.length - 1 ? handleMoveToMenuTop : null}
              ref={i === 0 ? mobileMenuFirstItemRef : null}
            >
              {item.title}
            </Link>
          </div>
        </li>
      );
    });
    if (isMobileMenu) {
      return (
        <ul
          className={classes.navMenu}
        >
          <NavItems items={sections} />
        </ul>
      );
    }
    return (
      <ul className={classes.navMenu}>
        <NavItems items={sections} />
      </ul>
    );
  };

  return (
    <Grid container className={classes.root} component="header">
      <Banner media={banner} mainPage={mainPage} lang={lang} />
      <Grid item className={classes.topArea}>
        <Grid container direction="row" justifyContent="space-between">
          <Link to={`/${lang}`} title={i18n[lang].toMainPage} aria-label={i18n[lang].toMainPage}>
            <img className={classes.logo} src="/assets/logo.png" alt={i18n[lang].toMainPage} />
          </Link>
          <Grid item className={classes.linkArea}>
            <nav aria-label={i18n[lang].mainMenu} role="navigation" id="main-menu">
              <Hidden lgUp>
                <IconButton
                  onClick={toggleMobileMenu}
                  aria-labelledby="main-menu"
                  aria-haspopup="true"
                  aria-expanded={mobileMenuOpen}
                  id="button-open-menu"
                  className={`${classes.iconButton} ${classes.mobileMenuOpen}`}
                >
                  <MenuRounded fontSize="inherit" />
                </IconButton>
                <Drawer
                  variant="temporary"
                  anchor="top"
                  open={mobileMenuOpen}
                  onClose={toggleMobileMenu}
                  PaperProps={{ className: classes.mobileMenuPaper }}
                  ModalProps={{ keepMounted: true }}
                >
                  <nav aria-label={i18n[lang].mainMenu} role="navigation" id="main-menu-mobile">
                    <div tabIndex={-1} ref={mobileMenuStartRef} />
                    <IconButton
                      className={classes.mobileMenuClose}
                      onClick={toggleMobileMenu}
                      onKeyDown={handleMoveToMenuBottom}
                      aria-labelledby="button-close-menu main-menu-mobile"
                      aria-expanded={mobileMenuOpen}
                      id="button-close-menu"
                    >
                      <CloseRounded />
                      { i18n[lang].close }
                    </IconButton>
                    <NavList isMobileMenu />
                    <div tabIndex={-1} ref={mobileMenuLastItemRef} />
                  </nav>
                </Drawer>
              </Hidden>
              <Hidden mdDown>
                <NavList />
              </Hidden>
            </nav>
          </Grid>
        </Grid>
      </Grid>
      <LanguagePicker lang={lang} pageSlugs={languageVersionSlugs} />
    </Grid>
  );
}

export default Header;
