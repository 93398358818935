export const TYPE_BLOGS = 'blogs';
export const TYPE_NEWS = 'news';
export const LANGUAGE_CODES = ['fi', 'sv', 'en'];
export const logoAlt = {
  fi: 'biopankki logo',
  en: 'biobank logo',
  sv: 'biobank logo',
};
export const i18n = {
  fi: {
    mainMenu: 'Päävalikko',
    subNavigation: 'Alavalikko',
    languageMenu: 'Kielivalikko',
    toMainPage: 'Etusivulle',
    latestPlural: 'Tuoreimmat',
    noArticles: 'Ei artikkeleita',
    navigatedTo: 'Siirryttiin sivulle',
    pageNotFound: 'Sivua ei löytynyt',
    articleNotFound: 'Artikkelia ei löytynyt',
    noContent: 'Ei sisältöä',
    anErrorOccured: 'Tapahtui virhe',
    close: 'Sulje',
    skipToContent: 'Siirry pääsisältöön',
    startVideo: 'Käynnistä video',
    stopVideo: 'Pysäytä video',
    loadMore: 'Lataa lisää',
    loadingSpinner: 'Ladataan',
    siteMap: 'Sivukartta',
    closeSitemap: 'Sulje sivukartta',
  },
  sv: {
    mainMenu: 'Huvudmeny',
    subNavigation: 'Undermeny',
    languageMenu: 'Språkmeny',
    toMainPage: 'Till huvudsidan',
    latestPlural: 'Senaste',
    noArticles: 'Inga artiklar',
    navigatedTo: 'Flyttade till',
    pageNotFound: 'Sidan kunde inte hittas',
    articleNotFound: 'Kunde inte hitta artikel',
    noContent: 'Inget innehåll',
    anErrorOccured: 'Ett fel inträffade',
    close: 'Stäng',
    skipToContent: 'Flytta till huvudinnehåll',
    startVideo: 'Starta videon',
    stopVideo: 'Stoppa videon',
    loadMore: 'Ladda mer',
    loadingSpinner: 'Laddar',
    siteMap: 'Webbplatskarta',
    closeSitemap: 'Stäng webbplatskarta',
  },
  en: {
    mainMenu: 'Main navigation',
    subNavigation: 'Subnavigation',
    languageMenu: 'Language menu',
    toMainPage: 'To main page',
    latestPlural: 'Latest',
    noArticles: 'No articles',
    navigatedTo: 'Navigated to',
    pageNotFound: 'Page Not Found',
    articleNotFound: 'Could not find article',
    noContent: 'No content',
    anErrorOccured: 'An Error Occured',
    close: 'Close',
    skipToContent: 'Skip to main content',
    startVideo: 'Start video',
    stopVideo: 'Stop video',
    loadMore: 'Load more',
    loadingSpinner: 'Loading',
    siteMap: 'Sitemap',
    closeSitemap: 'Close Sitemap',
  },
};

export const languagePicker = [
  { fi: 'Suomeksi' },
  { sv: 'På Svenska' },
  { en: 'In English' },
];
