import React from 'react';
import {
  Container,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: '0 0 20px',
    position: 'relative',
    [theme.breakpoints.values.sm]: {
      padding: '0 20px',
    },
  },
}));

function ContentContainer({ children }) {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.contentWrapper}>
      { children }
    </Container>
  );
}

export default ContentContainer;
