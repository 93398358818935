import React from 'react';
import { createRoot } from 'react-dom/client';
import { LiveAnnouncer } from 'react-aria-live';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
  uri: process.env.REACT_APP_CMS_GRAPHQL_ENDPOINT || '/api/graphql',
  cache: new InMemoryCache(),
});
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LiveAnnouncer>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </BrowserRouter>
    </LiveAnnouncer>
  </React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
