import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { i18n, languagePicker } from '../utils/const';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    right: 50,
    backgroundColor: 'white',
    padding: 12,
    borderRadius: '0 0 5px 5px',
    color: 'black',
    fontSize: '0.825rem',
    minWidth: '9.87rem',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

function LanguagePicker({ lang, pageSlugs }) {
  const classes = useStyles();

  function handleToAddress(newLang) {
    if (pageSlugs && pageSlugs[newLang]) {
      return `/${newLang}/${pageSlugs[newLang]}`;
    } return `/${newLang}`;
  }

  return (
    <Grid item className={classes.root}>
      <Grid
        container
        direction="row"
        component="nav"
        justifyContent="center"
        aria-label={i18n[lang].languageMenu}
      >
        {
          languagePicker.filter((langOptions) => !langOptions[lang]).map((item) => (
            Object.entries(item).map((langItem) => (
              <Grid key={[langItem[0]]} item>
                <Link
                  color="inherit"
                  to={() => handleToAddress(langItem[0])}
                  lang={langItem[0]}
                >
                  {langItem[1]}
                </Link>
              </Grid>
            ))
          )).reduce((prev, curr) => [prev, <span aria-hidden="true" key={`middot_${lang}`}>&nbsp;&middot;&nbsp;</span>, curr])
        }
      </Grid>
    </Grid>
  );
}

export default LanguagePicker;
