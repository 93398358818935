import React, { useEffect, useState } from 'react';
import { Backdrop, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { i18n } from '../utils/const';
import { srOnly } from '../utils/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 10,
    backgroundColor: 'rgba(256, 256, 256, 0.3)',
  },
  spinner: {
    padding: 15,
    borderRadius: '50%',
    backgroundColor: '#fff',
    boxSizing: 'content-box',
    boxShadow: '0 0 5px 0 #ccc',
  },
  srOnly,
}));

function Spinner({ lang, fullWindow = false, delay = 300 }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (delay > 0) {
      const timeout = setTimeout(() => {
        setOpen(true);
      }, delay);
      return () => { clearTimeout(timeout); };
    }
    return setOpen(true);
  }, [delay]);

  return fullWindow
    ? (
      <Backdrop open={open} className={classes.backdrop} timeout={{ enter: 500, exit: 0 }}>
        <CircularProgress
          color="primary"
          aria-label={i18n[lang].loadingSpinner}
          className={classes.spinner}
        />
      </Backdrop>
    )
    : (
      <Grid container justifyContent="center">
        <CircularProgress color="primary" aria-label={i18n[lang].loadingSpinner} />
      </Grid>
    );
}

export default Spinner;
