import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal, Fade, Backdrop,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from '../utils/const';
import { srOnly } from '../utils/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'underline',
    },
  },
  activeLink: {
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
  },
  list: {
    padding: 0,
    listStyle: 'none',
    '& li': {
      padding: '2px 0',
    },
  },
  listContainer: {
    marginBottom: '5px',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '20px',
    overflow: 'auto',
    position: 'relative',
    minWidth: '50%',
  },
  icon: {
    fontSize: '2.2rem',
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 42,
    right: 20,
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  srOnly,
}));

function ModalComponent({
  visible,
  handleVisibility,
  sections,
  lang,
  activeSlug,
  mainPageSlug,
  siteName,
}) {
  const classes = useStyles();

  function createLink(to, title) {
    if (to === activeSlug) {
      return (
        <Link
          to={to}
          onClick={() => handleVisibility(false)}
          className={classes.activeLink}
          aria-current="page"
        >
          {title}
        </Link>
      );
    }
    return (
      <Link
        to={to}
        onClick={() => handleVisibility(false)}
      >
        {title}
      </Link>
    );
  }

  return (
    <>
      <Modal
        role="navigation"
        component="nav"
        className={classes.modal}
        open={visible}
        onClose={() => handleVisibility(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="sitemap-modal"
      >
        <Fade in={visible}>
          <div className={classes.paper}>
            <button
              type="button"
              className={classes.closeBtn}
              onClick={() => handleVisibility(false)}
              aria-labelledby="closeModal"
            >
              <CloseIcon className={classes.icon} />
              <span className={classes.srOnly} id="closeModal" lang={lang}>
                {i18n[lang].closeSitemap}
              </span>
            </button>
            <h2 id="sitemap-modal">{i18n[lang].siteMap}</h2>
            <ul className={classes.list}>
              <li className={classes.listContainer}>
                {createLink(mainPageSlug, siteName)}
                <ul>
                  {
                  sections.map((item) => (
                    <li key={item.title} className={classes.listContainer}>
                      {createLink(item.slug, item.title)}
                      {
                        item.subPages && item.subPages.length > 0
                          ? (
                            <ul>
                              {
                                item.subPages.map((subPage) => (
                                  <li key={subPage.title}>
                                    {createLink(subPage.slug, subPage.title)}
                                  </li>
                                ))
                              }
                            </ul>
                          )
                          : null
                        }
                    </li>
                  ))
                  }
                </ul>
              </li>
            </ul>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default ModalComponent;
