import {
  Accordion, AccordionSummary, AccordionDetails, Grid,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { React, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RichText from './RichText';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    borderTop: '1px solid #ddd',
    '&.MuiAccordion-root:before': {
      height: 0, // to fix material ui duplicate border
    },
    '&.MuiAccordion-root:last-of-type': {
      borderBottom: '1px solid #ddd',
    },
  },
  titleText: {
    color: theme.palette.secondary.main,
    fontSize: '1.125rem',
    margin: 0,
  },
  summary: {
    '&:focus': {
      zIndex: 1, // focus outline to front of accordion
      outline: '5px auto -webkit-focus-ring-color',
      backgroundColor: 'white',
    },
  },
}));

function AccordionComponent({ items }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    items.map((item, index) => (
      item.title
        ? (
          <Accordion
            key={item.id}
            className={classes.root}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary aria-controls={`panel${index}`} className={classes.summary}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid xs={1} item style={{ display: 'inline-flex' }}>
                  {
                    expanded === `panel${index}`
                      ? <KeyboardArrowDownIcon fontSize="large" />
                      : <KeyboardArrowRightIcon fontSize="large" />
                  }
                </Grid>
                <Grid item xs={11} style={{ paddingTop: '2px' }}>
                  <h3 className={classes.titleText}>{item.title}</h3>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <RichText html={item.body} />
            </AccordionDetails>
          </Accordion>
        )
        : null
    ))
  );
}

export default AccordionComponent;
