import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link } from '@material-ui/core';
import { uploadUrl, externalUrl } from '../utils/url';

const useStyles = makeStyles(() => ({
  item: {
    padding: '0 10px 20px 0',
  },
}));

function ImageGrid({ imageLink, imagesPerRow }) {
  const classes = useStyles();
  const gridSize = imagesPerRow ? 12 / imagesPerRow : 12;

  if (imageLink.length === 0) return null;
  return (
    <Grid container justifyContent="flex-start" direction="row">
      {
          imageLink.map((item) => (
            <Grid key={item.id} item xs={gridSize} className={classes.item}>
              <Link href={externalUrl(item.link_url)}>
                <img src={uploadUrl(item.image.data.attributes.url)} alt={item.altText} />
              </Link>
            </Grid>
          ))
        }
    </Grid>
  );
}

export default ImageGrid;
