import React from 'react';
import RichText from '../components/RichText';
import TwoFeeds from '../components/layout/TwoFeeds';
import FeedAndChart from '../components/layout/FeedAndChart';
import JuicerFeed from '../components/JuicerFeed';
import InfoCard from '../components/InfoCard';
import AccordionComponent from '../components/AccordionComponent';
import ImageGrid from '../components/ImageGrid';

/*
  Input: API data model 'configuration.sections'
  Output:
  {
    sections: [
      Page: {
        title: str
        slug: str
        banner: { url, mime }
        subPages: Page[]
        parent: Page || null
      }
    ]
    index: {
      <slug>: Page
    }
  }
  */
function parseSiteStructure(cmsSections, lang) {
  const sections = [];
  const index = {};
  cmsSections.forEach((item) => {
    if (!item.page.data) return;
    const section = {
      title: item[`label_${lang}`],
      slug: item.page.data.attributes[`slug_${lang}`],
      banner: item.banner,
      subPages: [],
      parent: null,
    };
    if (section.slug) {
      sections.push(section);
      index[section.slug] = section;
    }
    if (!item.subPages) return;
    item.subPages.forEach((sub) => {
      if (!sub.page.data) return;
      const page = {
        title: sub.page.data.attributes[`title_${lang}`],
        slug: sub.page.data.attributes[`slug_${lang}`],
        banner: sub.page.banner || section.banner,
        parent: section,
      };
      if (page.slug) {
        section.subPages.push(page);
        index[page.slug] = page;
      }
    });
  });
  return { sections, index };
}

function parseComponent(component, pageLayout) {
  const componentType = component.__typename;
  if (componentType === 'ComponentContentBody') {
    // key needed when called from Array.map
    return (
      <RichText
        html={component.content}
        key={component.id}
        fullWidthLayout={pageLayout === 'full_width'}
      />
    );
  }

  if (componentType === 'ComponentFullWidthLayoutTwoFeedsInSeparateColumns') {
    return (
      <TwoFeeds
        leftFeed={component.LeftFeed}
        rightFeed={component.RightFeed}
        key={component.id}
      />
    );
  }

  if (componentType === 'ComponentFullWidthLayoutFeedAndChart') {
    return (
      <FeedAndChart
        feed={component.feed}
        chart={component.chart}
        background={component.background}
        key={component.id}
        fullWidthLayout
      />
    );
  }

  if (componentType === 'ComponentSocialMediaJuicerFeed') {
    return (
      <JuicerFeed
        key={component.id}
        feedId={component.feedId}
        title={component.title}
        showBranding={component.showBranding}
      />
    );
  }

  if (componentType === 'ComponentContentInfoCard') {
    return (
      <InfoCard
        key={component.id}
        direction={component.direction}
        heading={component.heading}
        items={component.Items}
      />
    );
  }

  if (componentType === 'ComponentContentAccordion') {
    return (
      <AccordionComponent
        key={component.id}
        items={component.AccordionItem}
      />
    );
  }

  if (componentType === 'ComponentContentImageGrid') {
    return (
      <ImageGrid
        key={component.id}
        imageLink={component.imageLink}
        imagesPerRow={component.imagesPerRow}
      />
    );
  }
  return null;
}

function getPageLanguageSlugs(page) {
  return {
    fi: page.slug_fi,
    sv: page.slug_sv,
    en: page.slug_en,
  };
}

export {
  parseSiteStructure,
  parseComponent,
  getPageLanguageSlugs,
};
