import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: '0 0 20px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  content: {
    padding: '40px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px',
    },
  },
  paper: {
    borderTop: `4px solid ${theme.palette.secondary.light}`,
    backgroundColor: '#fff',
  },
}));

function Boxed({ children }) {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.contentWrapper}>
      <Paper className={classes.paper} elevation={1}>
        <Grid container direction="row" alignItems="flex-start" className={classes.content}>
          { children }
        </Grid>
      </Paper>
    </Container>
  );
}

export default Boxed;
