export const srOnly = {
  position: 'absolute',
  width: 1,
  height: 1,
  padding: 0,
  overflow: 'hidden',
  clip: 'rect(0,0,0,0)',
  whiteSpace: 'nowrap',
  border: 0,
};

export const asideTitle = {
  marginTop: 0,
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '1.375rem',
  lineHeight: 1,
};
