import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import VideoControls from './VideoControls';
import { uploadUrl } from '../utils/url';

const useStyles = makeStyles((theme) => ({
  video: {
    minWidth: '100%',
    minHeight: '40vw',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain',
    zIndex: 1,
  },
  mediaSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '440px !important',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundSize: 'cover !important',
    backgroundColor: theme.palette.primary.main,
  },
  mediaSectionMain: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '580px !important',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundSize: 'cover !important',
    backgroundColor: theme.palette.primary.main,
  },
  image: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundPosition: '0 0',
    backgroundSize: 'cover',
  },
  gradient: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/assets/banner-gradient.png)',
    backgroundPosition: 'top left',
    backgroundRepeat: 'repeat-x',
  },
}));

function Banner(props) {
  const classes = useStyles(props);
  const Video = ({ media }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const toggleVideoPlaying = () => {
      if (isPlaying) videoRef.current.pause();
      else videoRef.current.play();
      setIsPlaying(!isPlaying);
    };

    return (
      <>
        <video
          src={uploadUrl(media.url)}
          ref={videoRef}
          alt=""
          className={classes.video}
          loop
          playsInline
          muted
          autoPlay
        >
          <track src="" kind="captions" />
          Your browser does not support the video tag
        </video>
        <VideoControls
          isPlaying={isPlaying}
          toggleVideoPlaying={toggleVideoPlaying}
          lang={props.lang}
        />
      </>
    );
  };

  const Img = ({ media }) => (
    <div className={classes.image} style={{ backgroundImage: `url('${uploadUrl(media.url)}')` }} />
  );
  return (
    <div>
      {props.mainPage
        ? (
          <Grid container className={classes.mediaSectionMain} role="presentation">
            {props.media
      && (props.media.mime.includes('video') ? (
        <Video media={props.media} />) : (
          <Img media={props.media} />
      ))}
            <div className={classes.gradient} />
          </Grid>
        ) : (
          <Grid container className={classes.mediaSection} role="presentation">
            {props.media
      && (props.media.mime.includes('video') ? (
        <Video media={props.media} />) : (
          <Img media={props.media} />
      ))}
            <div className={classes.gradient} />
          </Grid>
        )}
    </div>
  );
}

export default Banner;
