import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { queryFeedPageData } from '../utils/queries';
import ContentAndAside from '../components/layout/ContentAndAside';
import FeedPageItem from '../components/FeedPageItem';
import FeedAsideList from '../components/FeedAsideList';
import LoadedContent from '../components/LoadedContent';

function FeedPage({ type, slug, handleTitleChange }) {
  const [feedItems, setFeedItems] = useState([]);
  const { lang } = useParams();
  const { loading, error, data } = useQuery(queryFeedPageData(lang, type));
  const queryMatch = useRouteMatch(`/${lang}/${slug}/:itemSlug`);
  const itemSlug = queryMatch ? queryMatch.params.itemSlug : null;

  useEffect(() => {
    if (data) {
      setFeedItems(data.items.data);
    }
  }, [data]);

  if (error) return error.message;
  const firstItemSlug = feedItems.length > 0 ? feedItems[0].attributes.slug : null;
  const articleList = (
    <LoadedContent isLoading={loading}>
      <FeedAsideList
        items={feedItems}
        path={`/${lang}/${slug}`}
        currentItemSlug={itemSlug}
      />
    </LoadedContent>
  );
  // Note: slug given explicitly in path to prevent routing collision with other pages
  return (
    <Switch>
      <Route exact path={`/:lang/${slug}`}>
        <ContentAndAside
          content={(
            <LoadedContent isLoading={loading}>
              <FeedPageItem type={type} slug={firstItemSlug} isFeedRoot />
            </LoadedContent>
          )}
          aside={articleList}
          asideRight
          contentProps={{ 'aria-labelledby': 'feed-item-title' }}
        />
      </Route>
      <Route path={`/:lang/${slug}/:itemSlug`}>
        <ContentAndAside
          content={(
            <LoadedContent isLoading={loading}>
              <FeedPageItem type={type} slug={itemSlug} setDocumentTitle={handleTitleChange} />
            </LoadedContent>
          )}
          aside={articleList}
          asideRight
        />
      </Route>
    </Switch>
  );
}

export default FeedPage;
