import React from 'react';
import {
  Grid,
  Card,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 40,
    borderTop: `4px solid ${theme.palette.secondary.light}`,
  },
}));

function CardView({ leftColumn, rightColumn, ratio }) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
    >
      {
        leftColumn && (
          <Grid item md={rightColumn ? ratio.left : 12} xs={12}>
            <Card className={classes.content}>
              { leftColumn }
            </Card>
          </Grid>
        )
      }
      {
        rightColumn && (
          <Grid item md={leftColumn ? ratio.right : 12} xs={12}>
            <Card className={classes.content}>
              { rightColumn }
            </Card>
          </Grid>
        )
      }
    </Grid>
  );
}

export default CardView;
