import { createTheme } from '@material-ui/core/styles';

const a11yGreen = '#007e93';
const headingColor = '#0f486d';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004b87',
    },
    secondary: {
      light: '#00adb1',
      main: a11yGreen,
    },
    heading: headingColor,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Didact Gothic, sans-serif',
          color: '#000',
          backgroundColor: '#f7f7f7',
          fontSize: 17,
          lineHeight: 1.5,
          fontWeight: 500,
          overflowWrap: 'break-word',
        },
        img: {
          maxWidth: '100%',
        },
        a: {
          color: a11yGreen,
          textDecoration: 'none',
        },
        'a:hover, a:active, a.active': {
          color: '#000',
        },
        'a:active, a.active': {
          textDecoration: 'underline',
        },
        h1: {
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '2.75rem',
          margin: 0,
        },
        h2: {
          fontSize: '1.75rem',
          lineHeight: '2.25rem',
          marginTop: '1.25rem',
        },
        h3: {
          fontSize: '1.375rem',
          lineHeight: '1.875rem',
          marginTop: '1.25rem',
        },
        'h2, h3, h4': {
          color: headingColor,
        },
        'h2, h3, h4, h5, h6': {
          marginBottom: '1.25rem',
        },
      },
    },
  },
});

export default theme;
