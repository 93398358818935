import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from './Spinner';

function LoadedContent({ isLoading, showLoadingOverlay, children }) {
  const { lang } = useParams();
  if (showLoadingOverlay) {
    return (
      <div aria-busy={isLoading}>
        { isLoading && <Spinner lang={lang} fullWindow /> }
        { children }
      </div>
    );
  }
  return (
    <div aria-busy={isLoading}>
      { isLoading ? <Spinner lang={lang} /> : children }
    </div>
  );
}

export default LoadedContent;
