import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ContentContainer from './layout/ContentContainer';
import sanitizeHtmlString from '../utils/sanitizeHtmlString';

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    backgroundColor: '#fff',
    padding: '20px 0',
    fontSize: '1.25rem',
  },
  bodyText: {
    '& .linkButton': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      padding: '16px',
      display: 'inline-block',
      fontSize: '0.875rem',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      border: `1px solid ${theme.palette.primary.main}`,
      textDecoration: 'none',
      '&:hover': {
        transition: 'background-color 200ms ease',
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      },
    },
    '& h1': {
      color: '#0f486d',
    },
    '& .videoEmbed': {
      padding: '1em 0',
    },
    '& iframe': {
      maxWidth: '100%',
      border: 'none',
    },
    '& a': {
      textDecoration: 'underline',
    },
    '& .image-left': {
      float: 'left',
      marginRight: 10,
    },
    '& .image-right': {
      float: 'right',
      marginLeft: 10,
    },
    '& .image-center': {
      textAlign: 'center',
    },
    '& ul, ol': {
      padding: '20px',
    },
    '& li': {
      marginBottom: '20px',
    },
  },
}));

function RichText({ html, fullWidthLayout }) {
  const classes = useStyles();
  const history = useHistory();
  const { lang } = useParams();
  const editorContent = useRef();

  useEffect(() => {
    if (editorContent.current) {
      // intercept internal links created with the cms editor and use router to navigate
      const anchors = editorContent.current.querySelectorAll('a');
      anchors.forEach((a) => {
        if (a.href.startsWith(window.location.origin)) {
          const path = a.href.substring(window.location.origin.length);
          if (path.startsWith(`/${lang}/`)) { // don't touch links like /uploads/
            a.addEventListener('click', (evt) => {
              evt.preventDefault();
              history.push(path);
            });
          }
        }
      });
    }
  }, [html]);

  if (html === null) return null;

  const content = (
    <div
      className={classes.bodyText}
      ref={editorContent}
      // eslint-disable-next-line react/jsx-props-no-multi-spaces
      dangerouslySetInnerHTML={sanitizeHtmlString(html)}
    />
  );

  return fullWidthLayout
    ? (
      <div className={classes.textWrapper}>
        <ContentContainer>
          {content}
        </ContentContainer>
      </div>
    )
    : content;
}

export default RichText;
