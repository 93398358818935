import { makeStyles } from '@material-ui/core';
import React from 'react';
import { i18n } from '../utils/const';
import { srOnly } from '../utils/styles';

const useStyles = makeStyles({
  srOnlyFocusable: {
    ...srOnly,
    '&:active, &:focus': {
      width: 'auto',
      height: 'auto',
      overflow: 'visible',
      clip: 'auto',
      whiteSpace: 'normal',
      zIndex: 1031,
      position: 'fixed',
      background: '#fff',
      padding: 7,
      left: 0,
      top: 0,
    },
  },
});

function JumpToContent({ lang }) {
  const classes = useStyles();
  return (
    <a href="#main-content" className={classes.srOnlyFocusable}>{i18n[lang].skipToContent}</a>
  );
}

export default JumpToContent;
