import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../utils/format';
import { asideTitle } from '../utils/styles';

const useStyles = makeStyles({
  articleList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& li': {
      padding: 0,
      margin: '0px 35px 1em',
    },
    '& a': {
      display: 'block',
    },
  },
  asideTitle,
});

function FeedAsideList({
  items,
  currentItemSlug,
  path = '',
}) {
  const classes = useStyles();
  return (
    <ul className={classes.articleList}>
      {
        items.map((item) => (
          <li key={item.id}>
            <span className="date">{formatDateTime(item.attributes.published)}</span>
            <Link to={`${path}/${item.attributes.slug}`} className={item.attributes.slug === currentItemSlug ? 'active' : ''}>{item.attributes.title}</Link>
          </li>
        ))
      }
    </ul>
  );
}

export default FeedAsideList;
