import {
  Grid,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import FeedListing from '../FeedListing';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 40,
    borderTop: `4px solid ${theme.palette.secondary.light}`,
    backgroundColor: '#fff',
    [theme.breakpoints.values.sm]: {
      padding: '40px 20px',
      marginBottom: 20,
    },
  },
}));
function TwoFeeds({ leftFeed, rightFeed }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const LeftFeed = () => leftFeed && (
    <FeedListing
      type={leftFeed.feedType}
      title={leftFeed.title}
      titleId="feed-left-title"
      archiveLinkText={leftFeed.archiveLinkText}
      itemCount={leftFeed.itemCount}
    />
  );
  const RightFeed = () => rightFeed && (
    <FeedListing
      type={rightFeed.feedType}
      title={rightFeed.title}
      titleId="feed-right-title"
      archiveLinkText={rightFeed.archiveLinkText}
      itemCount={rightFeed.itemCount}
    />
  );

  return (
    <Grid container direction="row" justifyContent="space-between" spacing={isSmall ? 0 : 2}>
      <Grid item sm={12} md={6} component="section" aria-labelledby="feed-left-title">
        <Paper className={classes.paper}>
          <LeftFeed />
        </Paper>
      </Grid>
      <Grid item sm={12} md={6} component="section" aria-labelledby="feed-right-title">
        <Paper className={classes.paper}>
          <RightFeed />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TwoFeeds;
