import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { asideTitle } from '../utils/styles';
import pattern from '../utils/pattern';

ChartJS.register(...registerables);
const useStyles = makeStyles({
  asideTitle,
  chart: {
    maxHeight: 350,
  },
  table: {
    width: '100%',
    marginTop: '32px',
    '& th, td': {
      padding: '6px',
      fontSize: '0.9375rem',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      textAlign: 'right',
    },
  },
  tableTitle: {
    fontWeight: 'bolder',
    padding: '8px',
  },
});
function BarChart({ title, values, labels }) {
  const classes = useStyles();
  const chartData = {
    labels: [''],
    datasets: [
      {
        label: [labels[0]],
        backgroundColor: pattern.verticalLine('#0095ad'),
        borderColor: 'black',
        borderWidth: 1,
        hoverBackgroundColor: pattern.verticalLine('#007e93'),
        hoverBorderColor: 'black',
        data: [values[0]],
      },
      {
        label: [labels[1]],
        backgroundColor: pattern.horizontalLine('#00589e'),
        borderColor: 'black',
        borderWidth: 1,
        hoverBackgroundColor: pattern.horizontalLine('#004a85'),
        hoverBorderColor: 'black',
        data: [values[1]],
      },
    ],
  };
  return (
    <>
      <h2 className={classes.asideTitle}>{title}</h2>
      <div className={classes.chart} aria-hidden="true">
        <Bar
          data={chartData}
          width={100}
          height={400}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                  stepSize: 20000,
                },
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                labels: { boxWidth: 10 },
              },
            },
          }}
        />
      </div>
      <table className={classes.table}>
        <caption className={classes.tableTitle}>{title}</caption>
        <tbody>
          {
            values.map((item, index) => (
              <tr key={labels[index]}>
                <th scope="row">
                  {labels[index]}
                </th>
                <td>
                  {item}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
}

export default BarChart;
