import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FullWidthExtra from './layout/FullWidthExtra';
import { srOnly } from '../utils/styles';
import { i18n } from '../utils/const';

const useStyles = makeStyles({
  srOnly,
  juicerWrapper: {
    '& .juicer-feed .j-meta .j-twitter-date': {
      color: '#555',
    },
    '& .juicer-feed .j-meta .j-twitter-intents a.j-twitter-reply': {
      backgroundImage: 'url(/assets/twitter-reply-darkgray.png)',
    },
    '& .juicer-feed .j-meta .j-twitter-intents a.j-twitter-retweet': {
      backgroundImage: 'url(/assets/twitter-retweet-darkgray.png)',
    },
    '& .juicer-feed .j-meta .j-twitter-intents a.j-twitter-like': {
      backgroundImage: 'url(/assets/twitter-like-darkgray.png)',
    },
    '& .juicer-feed .j-message a': {
      textDecoration: 'underline !important',
    },
  },
  referral: {
    fontSize: '0.825rem',
    textAlign: 'center',
    '& a': {
      color: '#3a3a3a',
    },
  },
});

function JuicerFeed({ feedId, title, showBranding }) {
  const classes = useStyles();
  const { lang } = useParams();
  const titleId = `juicer-${feedId}`;
  const wrapperId = `juicer-wrapper-${feedId}`;
  const showFeed = feedId && title && true;

  useEffect(() => {
    if (showFeed) {
      const wrapper = document.getElementById(wrapperId);
      const juicer = document.getElementById('juicer');
      if (!juicer) {
        const jquery = document.createElement('script');
        jquery.src = 'https://ajax.googleapis.com/ajax/libs/jquery/2.2.0/jquery.min.js';
        jquery.async = true;
        const juicerScript = document.createElement('script');
        juicerScript.src = 'https://assets.juicer.io/embed.js';
        juicerScript.async = true;
        juicerScript.id = 'juicer';
        const juicerCss = document.createElement('link');
        juicerCss.href = 'https://assets.juicer.io/embed.css';
        juicerCss.type = 'text/css';
        juicerCss.rel = 'stylesheet';
        wrapper.appendChild(jquery);
        wrapper.appendChild(juicerCss);
        wrapper.appendChild(juicerScript);
        window.setPaginationText = () => {
          if (lang) {
            const loadMoreLink = window.document.querySelector('.j-paginate');
            loadMoreLink.innerHTML = i18n[lang].loadMore;
          }
        };
      }
    }
  }, [showFeed]);

  const content = (
    <div id={wrapperId} className={classes.juicerWrapper}>
      <h2 id={titleId} className={classes.srOnly}>{title}</h2>
      <ul className="juicer-feed" data-feed-id={feedId} data-per="9" data-after="setPaginationText()" />
      { showBranding && <p className={classes.referral}><a href="https://www.juicer.io">Powered by Juicer.io</a></p> }
    </div>
  );

  return showFeed && (
    <FullWidthExtra content={content} titleId={titleId} />
  );
}

export default JuicerFeed;
