import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LiveMessage } from 'react-aria-live';
import { scroller } from 'react-scroll';
import { queryPagesWithSlug } from '../utils/queries';
import { parseComponent, getPageLanguageSlugs } from '../utils/cmsContentParser';
import Header from './Header';
import Footer from './Footer';
import ErrorPage from './ErrorPage';
import ContentAndAside from '../components/layout/ContentAndAside';
import FullWidth from '../components/layout/FullWidth';
import FeedPage from './FeedPage';
import OneCol from '../components/layout/OneCol';
import SubNavItems from '../components/SubNavItems';
import { i18n } from '../utils/const';
import JumpToContent from '../components/JumpToContent';
import MainContent from '../components/MainContent';
import LoadedContent from '../components/LoadedContent';

const DEFAULT_LANGUAGEPICKER_SLUGS = { fi: null, sv: null, en: null };

function Page({
  siteStructure, siteConfig, mainPageSlug, feedSlugs,
}) {
  const [pageTitle, setPageTitle] = useState('');
  const [banner, setBanner] = useState(null);
  const [languagePickerSlugs, setLanguagePickerSlugs] = useState(DEFAULT_LANGUAGEPICKER_SLUGS);
  const [pageContent, setPageContent] = useState(null);
  const [pageExtraContent, setPageExtraContent] = useState(null);
  const [titleOverridden, setTitleOverriden] = useState(false);
  const [isMainPage, setIsMainPage] = useState(false);
  const startRef = useRef(null);
  const { lang, slug } = useParams();
  const { loading, error, data } = useQuery(queryPagesWithSlug(lang), {
    variables: { slug },
  });
  const location = useLocation();
  const isFeedPage = typeof feedSlugs[slug] !== 'undefined';
  const setDocumentTitle = (title) => {
    let titleContent = '';
    if (siteConfig && siteConfig.siteName) {
      titleContent = siteConfig.siteName;
    }
    if (title) {
      titleContent = `${title} - ${titleContent}`;
    }
    document.title = titleContent;
    document.lang = lang;
  };

  const setTitle = (title) => {
    setDocumentTitle(title);
    setPageTitle(title);
  };

  const overrideTitle = (title) => {
    setDocumentTitle(title);
    setTitleOverriden(true);
  };

  const getBanner = (page) => {
    if (page.banner.data) return page.banner.data.attributes;
    const pageSlug = page[`slug_${lang}`];
    if (
      siteStructure.index[pageSlug].banner.data != null
      && siteStructure.index[pageSlug].banner.data != null
    ) {
      return (
        siteStructure.index[pageSlug].banner.data.attributes
        && siteStructure.index[pageSlug].banner.data.attributes
      );
    }
    return siteConfig.defaultBanner;
  };

  const setPageComponents = (page, errorState = null) => {
    if (errorState) {
      setBanner(siteConfig.defaultBanner);
      setLanguagePickerSlugs(DEFAULT_LANGUAGEPICKER_SLUGS);
      if (errorState.name === 'PageNotFound') {
        setTitle(i18n[lang].pageNotFound);
        setPageContent(<ErrorPage message={i18n[lang].noContent} />);
      } else {
        setTitle(i18n[lang].anErrorOccured);
        setPageContent(<ErrorPage message={error.message} />);
      }
      return;
    }

    const content = page.content.map((component) => parseComponent(component, page.layout));
    const extra = page.extra.map(parseComponent);
    let contentContainer;
    // determine content type and layout
    if (isFeedPage) {
      contentContainer = (
        <FeedPage type={feedSlugs[slug]} slug={slug} handleTitleChange={overrideTitle} />
      );
    } else if (page.layout === 'boxed_one_column') {
      contentContainer = <OneCol content={content} />;
    } else if (page.layout === 'full_width') {
      contentContainer = <FullWidth content={content} />;
    } else {
      // determine sub navigation
      // page might not be in index, if not added to navigation
      const currentPage = siteStructure.index[slug] || {};
      const subPages = currentPage.subPages || [];
      const { parent } = currentPage;
      const subNavigation = parent ? parent.subPages : subPages;

      contentContainer = (
        <ContentAndAside
          content={content}
          aside={<SubNavItems pageList={subNavigation} activeSlug={slug} />}
          asideProps={{
            role: 'navigation',
            'aria-label': i18n[lang].subNavigation,
            component: 'nav',
          }}
        />
      );
    }
    setTitle(page.title);
    setTitleOverriden(false);
    setBanner(getBanner(page));
    setLanguagePickerSlugs(getPageLanguageSlugs(page));
    setPageContent(contentContainer);
    setPageExtraContent(extra);
    setIsMainPage(slug === mainPageSlug);
  };
  function changeId(pageData, id) {
    const test = pageData
    test.content[1].id = id;
    return test;
  }

  // Set error page
  useEffect(() => {
    if (error) {
      setPageComponents(null, error);
    }
  }, [error]);
  // Set page contents
  useEffect(() => {
    if (data && data.pages.data[0]) {
      if (data.pages.length === 0) {
        setPageComponents(null, { name: 'PageNotFound' });
      } else {
        const pageData = JSON.parse(JSON.stringify(data.pages.data[0].attributes));
        if (pageData.content.length > 1 && pageData.content[0].id === pageData.content[1].id) {
          const newData = changeId(pageData, '2');
          setPageComponents(newData);
        } else {
          setPageComponents(pageData);
        }
      }
    }
  }, [data]);
  // Scroll to top or anchor on page change
  useEffect(() => {
    if (pageContent && !loading) {
      if (location.hash) {
        const hash = location.hash.substr(1);
        scroller.scrollTo(hash, {
          duration: 1000,
          delay: 100,
          smooth: true,
        });
        // move focus
        let elem = window.document.getElementById(hash);
        if (!elem) {
          elem = window.document.querySelector(`[name='${hash}']`);
        }
        if (elem) {
          if (!(elem.tabIndex >= 0)) {
            // not sure if all browsers return -1 when not defined
            elem.tabIndex = -1;
          }
          elem.focus();
        }
      } else {
        startRef.current.focus();
        window.scrollTo(0, 0);
      }
    }
  }, [pageContent, location, loading]);
  return (
    <LoadedContent isLoading={loading} showLoadingOverlay>
      {pageContent && (
        <>
          <LiveMessage aria-live="assertive" message={pageTitle} />
          <div ref={startRef} tabIndex={-1}>
            <JumpToContent lang={lang} />
            <Header
              siteStructure={siteStructure}
              slug={slug}
              title={pageTitle}
              banner={banner}
              mainPage={isMainPage}
              lang={lang}
              languageVersionSlugs={languagePickerSlugs}
            />
            <MainContent
              title={pageTitle}
              content={pageContent}
              mainPage={isMainPage}
              isNotMainTitle={titleOverridden}
            />
            {pageExtraContent}
            <Footer
              contactHTML={siteConfig.footerContact}
              twitterUrl={siteConfig.twitterUrl}
              facebookUrl={siteConfig.facebookUrl}
              instagramUrl={siteConfig.instagramUrl}
              lang={lang}
              sections={siteStructure.sections}
              activeSlug={slug}
              mainPageSlug={mainPageSlug}
              siteName={siteConfig.siteName}
            />
          </div>
        </>
      )}
    </LoadedContent>
  );
}

export default Page;
